import React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"

import Footer from "../components/Footer/Footer"
import Navbar from "../components/Navbar/Navbar"
import Seo from "../components/Seo"

const Research = ({ data: { allWpProject } }) => {
  const allProjetcs = allWpProject.nodes

  return (
    <React.Fragment>
      <Seo title={"Research"} />
      <Navbar />
      <main className="marginTopNavbar">
        <section className="small-hero">
          <StaticImage
            draggable="false"
            src="../images/Arco SMA2.jpg"
            layout="fullWidth"
            loading="eager"
            style={{ height: "100%" }}
            alt="Image hero ATG"
          />
        </section>
        <div className="main-container">
          <section className="overview">
            <div id="areas-of-investigation" className="offset" />
            <div className="grid-8-col">
              <div className="col-3 ">
                <h1 className="sticky">Areas of Investigation</h1>
              </div>

              <div className="col-4">
                <p>
                  Currently, the Biomaterials and Stem Cell-Based Therapeutic
                  research group has two main avenues of research:
                </p>
                <br />
                <p>
                  <span>
                    1. Bioengineering platforms to modulate the differentiation
                    and maturation of stem cells.
                  </span>
                  <br /> One of the main objectives of the research group is to
                  develop biomaterials and bioengineering platforms for the
                  efficient differentiation, maturation and engraftment of stem
                  cells and their progenies (focus: cardiovascular lineages). We
                  are primarily working with human pluripotent stem cells
                  (induced pluripotent stem cells and human embryonic stem
                  cells) and fetal hematopoietic stem cells (human cord blood).
                  The group is developing scaffolds capable of retaining the
                  cells at the desired location, while serving as a template for
                  cell assembly, survival, differentiation and engraftment. The
                  group is also designing biomaterials that provide several
                  different types of information to stem cells, with the purpose
                  of controlling their differentiation. Finally, we are studying
                  new strategies based on topography and fluid shear stress to
                  modulate the differentiation of mesoderm cells such as
                  vascular cells and cardiomyocytes derived from human
                  pluripotent stem cells.
                </p>
                <br />
                <p>
                  <span>
                    2. Nanomedicine platforms to modulate the activity of stem
                    cells and their progenies.
                  </span>
                  <br /> The development of a wide spectrum of nanotechnologies
                  (referred as Nanomedicine by National Institutes of Health for
                  applications in the biomedical area) during the last years are
                  very promising for the study of stem cell biology and to
                  control exogenous and endogenous stem cells for regenerative
                  medicine. Our group is particularly interested to use these
                  tools to induce in vivo stem cell differentiation and to
                  mobilize stem cells from their niches to treat cardiovascular
                  diseases. For this purpose, we are developing nanomaterials
                  that release efficiently small molecules or non-coding RNA
                  (miRNAs) to manipulate stem cells or their progenies.
                </p>
                <br />
                <StaticImage
                  draggable="false"
                  className="image-border"
                  src="../images/areas-of-investigation.jpg"
                  alt="Areas of Investigation"
                />
              </div>
            </div>
          </section>
          <div className="horizontal-line" />
          <section className="overview">
            <div id="projects" className="offset" />
            <div className="grid-8-col">
              <h1 className="col-3">Projects</h1>
              <div className="col-4">
                <p>
                  {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna
                  tellus vitae mauris posuere ullamcorper ut et ultrices
                  volutpat. */}
                </p>
              </div>

              {allProjetcs.map((project, pIndex) => {
                const title = project.ProjectGQL.title
                const shortDescription = project.ProjectGQL.shortDescription
                const thumbnailImage =
                  project.ProjectGQL.thumbnailImage.localFile.childImageSharp
                    .gatsbyImageData
                const link = project.uri

                return (
                  <div className="project-card col-4" key={pIndex}>
                    <GatsbyImage
                      className="project-card__image"
                      alt={title}
                      image={thumbnailImage}
                    />

                    <div className="project-card__info">
                      <h2 className="project-card__info__title">{title}</h2>
                      <br />
                      <p className="project-card__info__description">
                        {shortDescription}
                      </p>
                      <br />
                      <Link className="project-card__info__link" to={link}>
                        Know More
                      </Link>
                    </div>
                  </div>
                )
              })}
            </div>
          </section>
          <div className="horizontal-line" />
          <section className="overview">
            <div id="significance" className="offset" />
            <div className="grid-8-col">
              <div className="col-3 ">
                <h1 className="sticky">Significance</h1>
              </div>

              <div className="col-4">
                <p>
                  Cardiovascular diseases (CVDs), a group of disorders of the
                  heart and blood vessels, are the number one cause of death
                  globally. More people die annually from CVDs than from any
                  other cause. In 2005, 17.5 million people around the world
                  died from CVDs, representing 30% of all global deaths (data
                  from World Health Organization). Of these deaths, an estimated
                  7.6 million were due to coronary heart disease and 5.7 million
                  were due to stroke. In Portugal, CVDs are the most common
                  cause of death and morbidity.
                </p>
                <br />
                <p>
                  Stem cells are an important source of cells for regenerative
                  medicine applications. Several clinical trials are underway to
                  investigate their therapeutic effects. Yet, it is of utmost
                  importance to understand the bioactivity of stem cells and
                  eventually to control it. The paracrine effect of stem cells
                  remains to be elucidated as well as new platforms to improve
                  stem cell survival after transplantation. Stem cells are also
                  an important source of cardiac and vascular cells for drug
                  screening and toxicological assessment. They can be an useful
                  in vitro model to study specific diseases and to find new
                  therapeutic targets.
                </p>
                <br />
                <StaticImage
                  draggable="false"
                  className="image-border"
                  src="../images/significance.jpg"
                  alt="Areas of Investigation"
                />
              </div>
            </div>
          </section>
          <div className="horizontal-line" />
          <section className="overview">
            <div id="approaches" className="offset" />
            <div className="grid-8-col">
              <div className="col-3 ">
                <h1 className="sticky">Approaches</h1>
              </div>

              <div className="col-4">
                <p>
                  We use many approaches to answer our research questions,
                  including the design of new biomaterials (nanoparticles,
                  microparticles, gels, coatings, etc…) with relevant biological
                  information, molecular and cell biology, microfluidic systems,
                  high content analysis, and animal experimentation.{" "}
                </p>
                <br />
                <p>
                  We have designed nanomaterials that can be monitored by
                  Magnetic Resonance Imaging (MRI) and efficiently deliver
                  miRNAs within cells, nanomaterials that can release
                  efficiently hydrophobic small molecules such as retinoic acid,
                  gels to efficiently transplant stem cells in chronic wounds,
                  etc…
                </p>
                <br />
                <p>
                  We use molecular analysis to identify signaling pathways that
                  are activated when cells are exposed to specific signals or
                  extracellular matrixes.
                </p>
                <br />
                <p>
                  We have high experience in the differentiation of pluripotent
                  stem cells into vascular lineages (endothelial and smooth
                  muscle cells).
                </p>
                <br />
                <p>
                  We are also working with disesase-specific induced pluripotent
                  stem cells (iPSCs) that can provide insights into the
                  mechanisms underlying cardiovascular diseases.
                </p>
                <br />
                <StaticImage
                  draggable="false"
                  className="image-border"
                  src="../images/approaches.jpg"
                  alt="Areas of Investigation"
                />
              </div>
            </div>
          </section>
          <div className="horizontal-line" />
          <section className="overview">
            <div id="contributions" className="offset" />
            <div className="grid-8-col">
              <div className="col-3 ">
                <h1 className="sticky">Contributions</h1>
              </div>

              <div className="col-4">
                <p>
                  Our laboratory has developed methodologies to efficiently
                  differentiate human pluripotent stem cells into vascular cells
                  (smooth muscle cells and endothelial cells), a stem cell based
                  therapy to treat diabetic chronic wounds, 3D scaffolds for
                  stem cell differentiation and transplantation, platforms to
                  modulate exogenous and endogenous stem cell niches, and a
                  nano-formulation that is able to monitor transplanted cells in
                  vivo by non-invasive imaging methods such as MRI and to
                  manipulate them simultaneously by intracellular delivery of
                  miRNAs.
                </p>
                <br />
                <p>
                  Our laboratory has started a biomaterials spin-off (Matera)
                  with headquarters at UC-BIOTECH(Cantanhede).
                </p>
                <br />
                <p>
                  Furthermore, the group has licensed 4 patents to companies in
                  the last 5 years. We expect to move one of our technologies to
                  a clinical trial in the next 2 years. The group has been
                  awarded in 2012 with an ERC grant.
                </p>
              </div>
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </React.Fragment>
  )
}

export const query = graphql`
  {
    allWpProject {
      nodes {
        ProjectGQL {
          title
          shortDescription
          thumbnailImage {
            localFile {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1.5)
              }
            }
          }
        }
        uri
      }
    }
  }
`

export default Research
